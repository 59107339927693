<template>
  <div>
    <div class="detailContent">
      <!-- 基本信息 -->
      <p class="detailTitle">
        {{ $t("SecurityMonitor.DeviceEvents.BasicInformation") }}
      </p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel" style="vertical-align: top"
              >{{ $t("public.DeviceName") }}：</span
            >
            <span class="detaildesc" style="vertical-align: top">{{
              detailData.deviceName
            }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t("public.Name") }}：</span>
            <span class="detaildesc">{{ detailData.userName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel"
              >{{ $t("SecurityMonitor.DeviceEvents.EquipmentModel") }}：</span
            >
            <span class="detaildesc">{{ detailData.baseInfo.model }}</span>
          </div>
          <!-- <div class="group">
              <span class="detailLabel">设备厂商：</span>
              <span class="detaildesc"></span>
            </div>
            <div class="group">
              <span class="detailLabel">合规状态：</span>
              <span class="detaildesc"></span>
            </div> -->
          <div class="group">
            <span class="detailLabel"  style="vertical-align: top;">{{ $t("public.SystemVersion") }}：</span>
            <span class="detaildesc" style="vertical-align: top;">{{ detailData.osVersion }}</span>
          </div>
          <div class="group">
            <span class="detailLabel"
              >{{ $t("SecurityMonitor.DeviceEvents.DeviceIP") }}：</span
            >
            <span class="detaildesc">{{ detailData.baseInfo.ip }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t("public.Username") }}：</span>
            <span class="detaildesc">{{ detailData.loginName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="vertical-align: top"
              >{{ $t("SecurityMonitor.DeviceEvents.UserDepartment") }}：</span
            >
            <span class="detaildesc" style="vertical-align: top">{{
              detailData?.groupFullName?.substr(groupFullNameShowByIndex)
            }}</span>
          </div>
          <!-- <div class="group">
              <span class="detailLabel">设备型号：</span>
              <span class="detaildesc">{{ detailData.model }}</span>
            </div> -->
          <!-- <div class="group">
              <span class="detailLabel">设备越狱：</span>
              <span class="detaildesc"></span>
            </div> -->
          <div class="group">
                        <!-- 2024.7.10wjw说产品要设备IMEI根据设备类型展示；android: 设备IMEI; iOS: UDID;LINUX:网卡Mac; Windows:网卡Mac -->
                        <span v-if="$common.getSystemName(detailData.osVersion)=='Android'" class="detailLabel"  style="vertical-align: top;"
                        >{{ $t("public.IMEIAndroid") }}：</span
                      >
                      <span v-if="$common.getSystemName(detailData.osVersion)=='iOS'" class="detailLabel"  style="vertical-align: top;"
                        >{{ $t("public.IMEIiOS") }}：</span
                      >
                      <span v-if="$common.getSystemName(detailData.osVersion)=='LINUX'" class="detailLabel"  style="vertical-align: top;"
                        >{{ $t("public.IMEIMac") }}：</span
                      >
                      <span v-if="$common.getSystemName(detailData.osVersion)=='Windows'" class="detailLabel"  style="vertical-align: top;"
                        >{{ $t("public.IMEIMac") }}：</span
                      >
                      <span v-if="$common.getSystemName(detailData.osVersion)=='Unknown'" class="detailLabel"  style="vertical-align: top;"
                        >{{ $t("public.IMEIUnknown") }}：</span
                      >
            <span class="detaildesc" style="vertical-align: top">{{
              detailData.baseInfo.imei
            }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="vertical-align: top"
              >{{ $t("public.InternetMethod") }}：</span
            >
            <span class="detaildesc" style="vertical-align: top">{{
              $filter.surfInterType(detailData.baseInfo.netType)
            }}</span>
          </div>
        </div>
      </div>
      <!-- 位置信息 -->
      <p class="detailTitle">
        {{ $t("SecurityMonitor.DeviceEvents.PositionInfor") }}
      </p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel"
              >{{ $t("SecurityMonitor.DeviceEvents.Country") }}：</span
            >
            <span class="detaildesc">{{
              detailData.locationInfo.country
            }}</span>
          </div>
          <div class="group">
            <span class="detailLabel"
              >{{ $t("SecurityMonitor.DeviceEvents.City") }}：</span
            >
            <span class="detaildesc">{{ detailData.locationInfo.city }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel"
              >{{ $t("SecurityMonitor.DeviceEvents.Province") }}：</span
            >
            <span class="detaildesc">{{
              detailData.locationInfo.province
            }}</span>
          </div>
          <div class="group">
            <span class="detailLabel"
              >{{ $t("public.LatitudeLongitude") }}：</span
            >
            <span
              class="detaildesc"
              v-if="detailData.locationInfo.longitude == ''"
            ></span>
            <span class="detaildesc" v-else
              >{{ detailData.locationInfo.longitude }},{{
                detailData.locationInfo.latitude
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group">
          <span class="detailLabel"
            >{{ $t("SecurityMonitor.DeviceEvents.Address") }}：</span
          >
          <span class="detaildesc">{{detailData.location}}</span>
        </div>
      </div>
      <!-- 风险信息 -->
      <p class="detailTitle">
        {{ $t("SecurityMonitor.DeviceEvents.RiskInfo") }}
      </p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t("public.EventType") }}：</span>
            <span class="detaildesc">{{ detailData.eventName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t("public.GenerationTime") }}：</span>
            <span class="detaildesc">{{ detailData.createTime }}</span>
          </div>

          <!-- <div class="group">
              <span class="detailLabel" style="display: block; float: left; width: 28%"
                >响应动作：</span
              >
              <span
                class="detaildesc"
                style="display: block; float: left; text-align: justify"
                >{{ arrResuly[0].label }}</span
              >
              <span
                v-for="(e, i) in arrResuly"
                :key="i"
                style="
                    width: 100%;
                    display: inline-block;
                    overflow: hidden;
                    color: #807e7e;
                    margin-left: 0;
                    line-height: 24px;
                    word-wrap: break-word;
                    word-break: break-all;
                  "
              >
                {{ e.label }} {{ new Date(e.time*1) | dateformat }}
              </span>
            </div> -->
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t("public.Eventlevel") }}：</span>
            <span
              class="detaildesc"
              v-if="detailData.level == '3'"
              style="color: #d9001b; cursor: auto"
              >{{ $t("SecurityMonitor.DeviceEvents.Tall") }}</span
            >
            <span
              class="detaildesc"
              v-if="detailData.level == '2'"
              style="color: #f59a23; cursor: auto"
              >{{ $t("SecurityMonitor.DeviceEvents.Centre") }}</span
            >
            <span
              class="detaildesc"
              v-if="detailData.level == '1'"
              style="color: #00cdcd; cursor: auto"
              >{{ $t("SecurityMonitor.DeviceEvents.Low") }}</span
            >
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group" style="width: 100%">
          <div
            class="detailLabel"
            style="
              float: left;
              height: 24px;
              line-height: 24px;
              text-align: left;
              pading-right: 5px;
              width: 13%;
            "
          >
            <span
              >{{ $t("SecurityMonitor.DeviceEvents.ResponseAction") }}：</span
            >
          </div>
          <div
            style="
              float: left;
              width: 78%;
              height: inherit;
              text-align: left;
              padding-right: 5px;
            "
            class="detaildesc"
          >
            <div
              v-for="(e, i) in arrResuly"
              :key="i"
              style="line-height: 24px; display: block"
            >
              <div>{{ i + 1 }}、{{ e}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group" style="width: 100%">
          <div
            class="detailLabel"
            style="
              float: left;
              height: 24px;
              line-height: 24px;
              text-align: left;
              pading-right: 5px;
              width: 13%;
              margin-top: 8px;
            "
          >
            <span
              >{{ $t("SecurityMonitor.DeviceEvents.JudgmentSteps") }}：</span
            >
          </div>
          <div
            style="
              float: left;
              width: 78%;
              height: inherit;
              text-align: left;
              padding-right: 5px;
              margin-top: 8px;
            "
            class="detaildesc"
          >
            <p
              v-for="(e, i) in detailData.decideStr"
              :key="i"
              style="
                width: 100%;
                display: inline-block;
                overflow: hidden;
                color: #807e7e;
                margin-left: 0;
                line-height: 24px;
                word-wrap: break-word;
                word-break: break-all;
                color: #606266;
              "
            >
              {{ i + 1 }}、{{ e }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",

  props: {
    detailData: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      // arr: [
      //   { value: '0', label: '告警日志', time: '1671441089625' },
      //   { value: '0', label: '告警日志', time: '1671441089625' },
      //   { value: '0', label: '告警日志', time: '1671441089625' }
      // ],
      arrResuly: [],
      rule: [
        { value: "0", label: "告警日志" },
        { value: "1", label: "告警提示" },
        { value: "2", label: "清除数据" },
        { value: "3", label: "强制注销" },
        { value: "4", label: "停用设备" },
        { value: "5", label: "恢复出厂" },
        { value: "6", label: "禁止访问" },
        { value: "7", label: "锁定" },
      ],
      timer: "",
      groupFullNameShowByIndex: "",
    };
  },
  mounted() {
    // console.log(this.detailData,'this.detailData');
    this.loadDate();
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
  },
  methods: {
    loadDate() {
      // console.log(this.detailData);
      console.log(this.detailData, "this.detailData");
      this.arrResuly = eval(this.detailData.riskInfo);
      console.log(this.arrResuly,'this.arrResuly');
      // this.arrResuly.forEach((t) => {
      //   this.$t("SecurityMonitor.DeviceEvents.ArrResuly").forEach((i) => {
      //     if (i.value == t.value) {
      //       t.label = i.label;
      //     }
      //   });
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.detailContent {
  width: 100%;
  // height: 469px !important;
  // overflow: auto !important;
  .detailTitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
  }
  .detailbox {
    width: 100%;
    display: flex;
    font-size: 12px;
    .boxLeft {
      width: 50%;
      padding-left: 68px;
      line-height: 24px;
      .group {
        margin-top: 8px;
        .detailLabel {
          width: 44%;
          text-align: left;
        }
        .detaildesc {
          width: 56%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }
    .boxRight {
      width: 50%;
      line-height: 24px;
      .group {
        margin-top: 8px;
        .detailLabel {
          width: 23%;
          text-align: left;
        }
        .detaildesc {
          width: 67%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }
  }
  .boxBot {
    width: 100%;
    padding-left: 68px;
    font-size: 12px;
    .group {
      margin-top: 8px;
      .detailLabel {
        width: 22%;
        text-align: left;
        padding-right: 5px;
      }
      .detaildesc {
        width: 78%;
        display: inline-block;
      }
    }
  }
}
.detailContent .boxBot .group .detailLabel[data-v-70181544] {
  width: 13%;
}
</style>
