<template>
  <div class="SensitiveMonitor">
    <el-row :gutter="4" class="mt15" style="display: flex">
      <el-col :span="3">
        <!-- 起始日期 -->
        <el-date-picker
          :clearable="false"
          size="small"
          style="width: 100%"
          v-model="formInline.startTime"
          type="date"
          :placeholder="$t('SecurityMonitor.DeviceEvents.SelectDate')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions0"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker
          :clearable="false"
          size="small"
          style="width: 100%"
          v-model="formInline.endTime"
          type="date"
          :placeholder="$t('SecurityMonitor.DeviceEvents.SelectDate')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions1"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <!-- 事件类型 -->
        <el-select
          v-model="formInline.eventName"
          :placeholder="$t('public.BehaviorType')"
          style="width: 100%"
          size="small"
          class="placeInput"
          @change="eventNameChang"
        >
          <el-option
            v-for="item in TypeOptions"
            :key="item.type"
            :label="
              $t(`SecurityMonitor.DeviceEvents.TypeOptions.${item.label}`)
            "
            :value="item.type"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <!-- 事件级别 -->
        <el-select
          style="width: 100%"
          v-model="formInline.level"
          :placeholder="$t('public.Behaviorlevel')"
          size="small"
          class="placeInput"
          @change="eventOptionsChang"
        >
          <el-option
            v-for="item in eventOptions"
            :key="item.value"
            :label="
              $t(`SecurityMonitor.DeviceEvents.eventOptions.${item.label}`)
            "
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <!-- 位置 -->
      <el-col :span="3">
        <el-cascader
          size="small"
          style="width: 100%"
          :options="cityOptions"
          collapse-tags
          :props="props"
          v-model="formInline.location"
          :placeholder="$t('public.Position')"
          filterable
          class="placeInput"
        ></el-cascader>
      </el-col>
      <select-input
        :options="devNameOptions"
        @selectSearchChange="selectSearchChange"
        @searchList="onSubmit"
      ></select-input>
      <el-col :span="3">
        <el-button size="small" type="primary" @click="advancedBtn">{{
          $t("public.Derive")
        }}</el-button>
      </el-col>
    </el-row>
    <!-- 点击选中只显示对应内容 -->
    <el-row :gutter="4" class="elRow" style="position: relative">
      <div
        style="
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          right: 5px;
          top: -32px;
          cursor: pointer;
        "
      >
        <!-- 弹出框 -->
        <el-popover trigger="click" popper-class="LinkageTable">
          <el-checkbox-group @change="tableTitleTime" v-model="checkList">
            <el-checkbox
              v-for="(item, index) in tradeSelections"
              :key="index"
              :label="
                $t(`SecurityMonitor.DeviceEvents.Tablelinkage.${item.eng}`)
              "
              :checked="item.isChecked"
              :disabled="item.flag"
              @click.native="sss(item.eng, index, item.flag)"
              >{{
                $t(`SecurityMonitor.DeviceEvents.Tablelinkage.${item.name}`)
              }}</el-checkbox
            >
          </el-checkbox-group>
          <img
            slot="reference"
            src="@/images/showConfig.png"
            alt=""
            style="width: 20px; height: 20px"
          />
        </el-popover>
      </div>
      <!-- 表格 -->
      <el-table
        size="mini"
        ref="myTable"
        :data="tableData"
        stripe
        fit
        tooltip-effect="dark"
        class="tableClass"
        @sort-change="sort"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
        :default-sort="{ prop: 'CREATETIME', order: 'ascending' }"
      >
        <template>
          <el-table-column
            v-if="$filter.nameFilter(formThead, tradeSelections)"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              width="120"
              v-for="(item, index) in formThead"
              :key="index"
              :label="item.name"
            >
              <template slot-scope="scope">
                {{ scope.row[item.eng] }}
              </template>
            </el-table-column>
          </el-table-column>
        </template>
        <el-table-column
          :show-overflow-tooltip="true"
          fixed
          width="120"
          :label="$t('public.DeviceName')"
          prop="DEVIVENAME"
          v-if="tradeSelections[tradeSelections.length - 16].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.deviceName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          fixed
          width="120"
          :label="$t('public.EventType')"
          prop="EVENTNAME"
          v-if="tradeSelections[tradeSelections.length - 15].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.eventName }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('public.Eventlevel')"
          width="120"
          prop="LEVEL"
          v-if="tradeSelections[tradeSelections.length - 14].isChecked"
        >
          <template slot-scope="scope">
            <span
              style="color: #d9001b; cursor: pointer"
              v-if="scope.row.level == '3'"
              >{{ $t("SecurityMonitor.DeviceEvents.Tall") }}</span
            >
            <span
              style="color: #f59a23; cursor: pointer"
              v-if="scope.row.level == '2'"
              >{{ $t("SecurityMonitor.DeviceEvents.Centre") }}</span
            >
            <span
              style="color: #00cdcd; cursor: pointer"
              v-if="scope.row.level == '1'"
              >{{ $t("SecurityMonitor.DeviceEvents.Low") }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.SystemVersion')"
          prop="OS_VERSION"
          v-if="tradeSelections[tradeSelections.length - 13].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.osVersion }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Position')"
          v-if="tradeSelections[tradeSelections.length - 12].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.location }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.LatitudeLongitude')"
          v-if="tradeSelections[tradeSelections.length - 11].isChecked"
        >
          <template slot-scope="scope">
            <span
              class="detaildesc"
              v-if="
                scope.row.locationInfo.longitude == '' ||
                scope.row.locationInfo.latitude == ''
              "
            ></span>
            <span class="detaildesc" v-else
              >{{ scope.row.locationInfo.longitude }},{{
                scope.row.locationInfo.latitude
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.InternetMethod')"
          prop="NET_TYPE"
          v-if="tradeSelections[tradeSelections.length - 10].isChecked"
        >
          <template slot-scope="scope">
            {{ $filter.surfInterType(scope.row.baseInfo.netType) }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.ExternalIP')"
          prop="OUT_IP"
          v-if="tradeSelections[tradeSelections.length - 9].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.baseInfo.outIp }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.IntranetIP')"
          prop="IP"
          v-if="tradeSelections[tradeSelections.length - 8].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.baseInfo.ip }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="WIFI-SSID"
          prop="WIFI_SSID"
          v-if="tradeSelections[tradeSelections.length - 7].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.baseInfo.wifiSSid }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="WIFI-MAC"
          prop="WIFI_MAC"
          v-if="tradeSelections[tradeSelections.length - 6].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.baseInfo.wifiMac }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Username')"
          prop="LOGINNAME"
          v-if="tradeSelections[tradeSelections.length - 5].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.loginName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Name')"
          prop="USERNAME"
          v-if="tradeSelections[tradeSelections.length - 4].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.userName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="140"
          :label="$t('public.Section')"
          prop="GROUPFULLNAME"
          v-if="tradeSelections[tradeSelections.length - 3].isChecked"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupFullName">{{
              row.groupFullName.substr(groupFullNameShowByIndex)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="140"
          :label="$t('public.GenerationTime')"
          prop="CREATETIME"
          v-if="tradeSelections[tradeSelections.length - 2].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('public.Controls')">
          <template slot-scope="scope">
            <span
              size="small"
              @click="details(scope.row)"
              style="color: #d38a08; cursor: pointer"
              >{{ $t("public.Details") }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        :page="currentPage"
        :limit="pageSize"
        :total="total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </el-row>
    <!-- 详情弹框 -->
    <Eldialog
      v-if="outerVisible"
      @handleClose="handleClose"
      :title="$t('public.Details')"
      :dialogVisible="outerVisible"
      :flagbtn="false"
      :num="'470px'"
      :destroy-on-close="true"
    >
      <DeviceEventsDetail :detailData="detailData" />
    </Eldialog>
  </div>
</template>
<script>
import selectInput from "@/components/selectInput";
import options from "@/utils/country-level3-data"; //三级城市数据
import { regionData } from "element-china-area-data"; //引入
import pagination from "@/components/pagination/page";
import Eldialog from "@/components/elDialog/index.vue";
import DeviceEventsDetail from "./components/DeviceEventsDetail.vue";
export default {
  components: {
    selectInput,
    Eldialog,
    pagination,
    DeviceEventsDetail,
  },

  data() {
    return {
      key: 1, //table  key
      flag: 1,
      props: { multiple: true },
      number: 0,
      formCustom: {
        Detail: "", //详细地址信息
        address: "", //省份城市
        DetailMap: "", //城市地址详细地址拼接
      },
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头
      cityOptions: options, // 城市数据
      pickerOptions1: {},
      pickerOptions0: {},
      //表格联动 isChecked：true选中(默认)false不选中    flag：true是禁用(不可编辑)false不禁用(可编辑)
      tradeSelections: [
        { eng: "deviceName", name: "设备名称", isChecked: true, flag: true },
        { eng: "eventName", name: "事件类型", isChecked: true, flag: true },
        { eng: "level", name: "事件级别", isChecked: true, flag: false },
        { eng: "osVersion", name: "系统版本", isChecked: true, flag: false },
        { eng: "location", name: "位置", isChecked: true, flag: false },
        {
          eng: "longitudeAndLatitude",
          name: "经纬度",
          isChecked: false,
          flag: false,
        },
        { eng: "netType", name: "上网方式", isChecked: false, flag: false },
        { eng: "outIp", name: "外网IP", isChecked: true, flag: false },
        { eng: "ip", name: "内网IP", isChecked: false, flag: false },
        { eng: "wifiSsid", name: "WIFI-SSID", isChecked: false, flag: false },
        { eng: "wifiMac", name: "WIFI-MAC", isChecked: false, flag: false },
        { eng: "loginName", name: "用户名", isChecked: true, flag: false },
        { eng: "userName", name: "姓名", isChecked: false, flag: false },
        { eng: "groupFullName", name: "部门", isChecked: true, flag: false },
        { eng: "createTime", name: "产生时间", isChecked: true, flag: false },
        { eng: "decide", name: "操作", isChecked: true, flag: true },
      ],
      total: 0,
      pageSize: 10, //每页显示条数
      tableData: [], //列表data
      currentPage: 1, //当前页
      orderColume: "CREATETIME", //排序字段
      placeholder: "设备名称",
      outerVisible: false,
      orderRule: "DESC", //排序规则ASC、DESC
      seleShift: "deviceName", // 选中下拉框转译
      tradeSelections1: [], // 表格联动勾选完数据
      selectedOptions: [], //存放默认值
      groupFullNameShowByIndex: "", //用户部门截取的下标
      formInline: {
        level: "", //下拉
        input: "",
        eventName: "",
        endTime: "",
        devName: "", // 设备名称
        location: [],
        startTime: "", // 选择日期
        deviceName: "设备名称",
      },
      detailData: {}, //详情data
      // 条件查询 事件类型 长期离线，默认选择事件类型
      TypeOptions: [
        { type: "", label: "事件类型" },
        { type: "长期离线", label: "长期离线" },
      ],

      // 条件查询 事件级别 高、中、低，默认选择事件级别
      eventOptions: [
        { value: "", label: "事件级别" },
        { value: "3", label: "高" },
        { value: "2", label: "中" },
        { value: "1", label: "低" },
      ],
      // 条件查询 设设备名称、用户名、姓名、部门：可进行模糊查询，下拉框默认选择设备名称
      devNameOptions: [
        { value: "deviceName", label: "设备名称" },
        { value: "loginName", label: "用户名" },
        { value: "userName", label: "姓名" },
        { value: "groupFullName", label: "部门" },
      ],
    };
  },

  watch: {
    checkList(val) {
      this.formThead = this.tradeSelections.filter(
        (i) => val.indexOf(i.eng) >= 0
      );
      this.key - this.key + 1;
    },
  },

  created() {},

  //取token
  beforeMount() {
    const token = sessionStorage.getItem("userName");
  },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );

    this.formInline.startTime = this.$common.getAnyMonth("-", -1);
    this.formInline.endTime = this.$common.getDate();
    this.getDataList();
  },
  updated() {
    // myTable是表格的ref属性值
    if (this.$refs.myTable && this.$refs.myTable.doLayout) {
      this.$refs.myTable.doLayout();
    }
  },
  methods: {
    selectSearchChange() {},
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 默认显示当天时间
    getdatatime() {
      //默认显示今天
      return new Date().getTime();
    },
    // 排序@sort-change="sort"
    sort(column) {
      if (column.order === "ascending") {
        this.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      this.onSubmit(); //再次调用查询接口
    },
    // 详情关闭按钮
    handleClose() {
      this.outerVisible = false;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用查询方法
      this.getDataList();
    },
    sss(index, key, event) {
      if (event == true) {
        return false;
      }
      if (this.number == 1) {
        if (
          index == "deviceName" ||
          index == "eventName" ||
          index == "level" ||
          index == "osVersion" ||
          index == "location" ||
          index == "longitudeAndLatitude" ||
          index == "netType" ||
          index == "outIp" ||
          index == "ip" ||
          index == "wifiSsid" ||
          index == "wifiMac" ||
          index == "loginName" ||
          index == "userName" ||
          index == "groupFullName" ||
          index == "createTime"
        ) {
          this.tradeSelections[key].isChecked =
            !this.tradeSelections[key].isChecked;
        }
        this.number = 0;
      } else {
        this.number = 1;
      }
    },
    changeDate() {
      let start = new Date(this.formInline.startTime).getTime();
      let end = new Date(this.formInline.endTime).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
    },
    // 列表
    async getDataList(val) {
      this.changeDate();
      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        level: this.formInline.level, //行为级别
        location: location,
        eventName: this.formInline.eventName, //事件类型
        customKey: this.selectVal ? this.selectVal.deviceName : this.seleShift,
        customVal: this.selectVal
          ? this.selectVal.InputText
          : this.formInline.input,
      };
      const res = await this.$axios.post(
        "/httpServe/deviceEvent/getDataInfo",
        parma,
        true
      );
      const processedPeople = res.data.content;
      for (var i = 0; i < processedPeople.length; i++) {
        var obj2 = processedPeople[i];
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == "") {
            obj2[key] = "N/A";
          }
        }
        for (var j = 0; j < this.TypeOptions.length; j++) {
          if (processedPeople[i].type == this.TypeOptions[j].type) {
            processedPeople[i].type = this.TypeOptions[j].label;
          }
        }
      }
      this.tableData = processedPeople.map((item) => {
        // 创建一个新的对象，包含原始对象的所有字段
        const newItem = { ...item };
        // 将 baseInfo 字段从 JSON 字符串转换为对象
        newItem.baseInfo = JSON.parse(newItem.baseInfo);
        // 将 locationInfo 字段从 JSON 字符串转换为对象
        newItem.locationInfo = JSON.parse(newItem.locationInfo);
        // 返回新的对象
        return newItem;
      });
      this.total = res.data.total;
      console.log(this.tableData, "this.tableData");
    },
    changeName(item) {
      this.formInline.deviceName = item;
      this.placeholder = item;
      this.devNameOptions.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value;
          console.log(this.seleShift);
        }
      });
    },
    //条件查询 事件类型 切换事件
    eventNameChang(val) {
      console.log(val, "val");
      this.formInline.eventName = val;
    },
    //条件查询 事件级别 切换事件
    eventOptionsChang(val) {
      console.log(val, "val");
      this.formInline.level = val;
    },
    //查询
    async onSubmit(val) {
      this.selectVal = val;
      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }
      console.log(this.formInline.startTime, "this.formInline.startTime");
      this.currentPage = 1;
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.formInline.startTime.split(" ")[0],
        endTime: this.formInline.endTime.split(" ")[0],
        eventName: this.formInline.eventName,
        level: this.formInline.level,
        location: location,
        customKey: val ? val.deviceName : "",
        customVal: val ? val.InputText : "",
      };
      console.log(parma);
      const res = await this.$axios.post(
        "/httpServe/deviceEvent/getDataInfo",
        parma,
        true
      );
      const processedPeople = res.data.content;
      for (var i = 0; i < processedPeople.length; i++) {
        var obj2 = processedPeople[i];
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == "") {
            obj2[key] = "N/A";
          }
        }
        for (var j = 0; j < this.TypeOptions.length; j++) {
          if (processedPeople[i].type == this.TypeOptions[j].type) {
            processedPeople[i].type = this.TypeOptions[j].label;
          }
        }
      }
      this.tableData = processedPeople.map((item) => {
        // 创建一个新的对象，包含原始对象的所有字段
        const newItem = { ...item };
        // 将 baseInfo 字段从 JSON 字符串转换为对象
        newItem.baseInfo = JSON.parse(newItem.baseInfo);
        // 将 locationInfo 字段从 JSON 字符串转换为对象
        newItem.locationInfo = JSON.parse(newItem.locationInfo);
        // 返回新的对象
        return newItem;
      });
      this.total = res.data.total;
    },
    //导出
    async advancedBtn() {
      let arrOut = [];
      this.tradeSelections.forEach((item) => {
        if (item.isChecked == true && item.name != this.$t("public.Controls")) {
          arrOut.push(item.eng); //去重
          this.tradeSelections1 = [...new Set(arrOut)];
        }
      });
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMzNzU1NjQsInVzZXJJZCI6MSwiaWF0IjoxNjMyNjU1NTY0fQ.OtDA-uF3NfP8xqj902kqxPu54UPgs8SYVY1nBTl1Ifnvoxw_-MPmcN_DKeYDQXHZfEizztITgMABrO0tBmvshA";

      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );

      let groupId = JSON.parse(userString).userId;

      this.userId = groupId;

      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }
      var a = JSON.stringify(this.formInline.location);
      var b = JSON.parse(a);

      var arr = [];
      if (this.formInline.location.length > 0) {
        arr = b[0].join("/");
      }

      var parma = {
        currentPage: this.currentPage,
        pageSize: this.total,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        // orderColume: this.orderColume,
        // orderRule: this.orderRule,
        eventName: this.formInline.eventName,
        level: this.formInline.level,
        location: location,
        eventName: "",

        customKey: this.selectVal.deviceName
          ? this.selectVal.deviceName
          : this.seleShift,
        customVal: this.selectVal.InputText
          ? this.selectVal.InputText
          : this.formInline.input,
        exportField: this.tradeSelections1,
      };
      const res = await this.$axios.postExport(
        "/httpExport/deviceEvent/exportExcel",
        parma,
        true,
        this.userId
      );
      const link = document.createElement("a");
      console.info(new Date());

      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = "exportDeviceEvents_" + Date.parse(new Date()) + ".xls";

      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 详情
    details(info) {
      this.outerVisible = true;
      this.detailData = info;
      this.detailData["decideStr"] = eval(this.detailData.decide);
    },
    //配置项监听事件
    tableTitleTime(event) {},
  },
};
</script>

<style lang="scss" scoped>
.SensitiveMonitor {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
.location {
  &::v-deep {
    & ::placeholder {
      color: #606266;
    }
  }
}
@-moz-document url-prefix() {
  .location {
    &::v-deep {
      & ::placeholder {
        color: #606266;
        opacity: 1 !important; /*火狐默认有opacity:0.4; */
      }
    }
  }
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 130px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 140px;
}
.el-form-item__content {
  width: 100%;
  display: flex;
  margin: 0 !important;
}
.detailContent .boxBot .group .detailLabel[data-v-70181544] {
  width: 13%;
}
/* 设置高优先，以覆盖内联样式 Table组件固定列底部有一条白线
  ::v-deep  .el-table__fixed{
    height: 100vh !important;
  }
  ::v-deep  .el-table__fixed-right{
    height: 100vh !important;
  }*/
::v-deep .el-table__fixed::before {
  background-color: transparent !important; /*背景色完全透明*/
}
::v-deep .el-table__fixed-right::before {
  background-color: transparent !important; /*背景色完全透明*/
}
.placeInput {
  /* ---------------------------------------注意区分组件 */
  ::v-deep .el-cascader__tags .el-cascader__search-input::placeholder {
    color: #606266 !important;
  }
  /* 谷歌 */
  ::v-deep
    .el-cascader__tags
    .el-cascader__search-input::-webkit-input-placeholder {
    color: #606266 !important;
  }
  /* 火狐::-moz-placeholder */
  ::v-deep .el-cascader__tags .el-cascader__search-input::-moz-placeholder {
    color: #606266 !important;
    opacity: 1 !important; /*火狐默认有opacity:0.4; */
  }
  /*ie*/
  ::v-deep .el-cascader__tags .el-cascader__search-input:-ms-input-placeholder {
    color: #606266 !important;
  }
}

::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
